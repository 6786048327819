import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, onSnapshot } from 'firebase/firestore';
import './ChatPage.css';

function StudentChatPage() {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const chatRef = doc(db, 'chats', chatId);
    console.log("Chat reference path:", chatRef.path); // Log the chat reference path

    const initializeChat = async () => {
        try {
            const chatDoc = await getDoc(chatRef);

            if (chatDoc.exists()) {
                onSnapshot(chatRef, (doc) => {
                    const fetchedMessages = doc.data().messages || [];
                    setMessages(fetchedMessages);
                    console.log("Fetched messages:", fetchedMessages); // Log fetched messages
                });
            } else {
                await setDoc(chatRef, { messages: [] });
                console.log('Chat document created:', chatId);
            }
        } catch (error) {
            console.error('Error initializing chat:', error);
        }
    };

    initializeChat();
}, [chatId]);



  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
  
    const chatRef = doc(db, 'chats', chatId);
    const message = {
      senderId: auth.currentUser.uid,
      message: newMessage,
      timestamp: new Date(),
    };
  
    try {
      await updateDoc(chatRef, {
        messages: arrayUnion(message),
      });
  
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };
  
  

  return (
    <div className="chat-container">
        <div className="chat-header">Chat</div>
        <div className="messages">
            {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.senderId === auth.currentUser.uid ? 'sender' : 'recipient'}`}>
                    {msg.message}
                </div>
            ))}
        </div>
        <div className="input-container">
            <input 
                type="text" 
                value={newMessage} 
                onChange={(e) => setNewMessage(e.target.value)} 
                placeholder="Type a message..."
            />
            <button onClick={handleSendMessage}>Send</button>
        </div>
    </div>
);

}

export default StudentChatPage;
