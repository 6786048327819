// src/components/ConfirmSummary.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ConfirmSummary.css';

const ConfirmSummary = () => {
  const location = useLocation();
  const {
    summary: initialSummary, description, ownerId, isCollaborating, collaboratingBusinessId, studentId, createdAt,
  } = location.state || {};
  const [summary, setSummary] = useState(initialSummary || '');
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  const handleNext = () => {
    // Navigate to the budget setting page with all parameters passed through
    navigate('/set-budget', {
      state: {
        title,
        summary,
        description,
        ownerId,
        isCollaborating,
        collaboratingBusinessId,
        studentId,
        createdAt,
      },
    });
  };

  return (
    <div className="confirm-summary-container">
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter your project title"
        className="title-input"
      />
      <textarea
        value={summary}
        onChange={(e) => setSummary(e.target.value)}
        placeholder="Edit your project summary..."
        className="summary-box"
      />
      <button onClick={handleNext} className="fund-button">
        fund <span className="arrow">→</span>
      </button>
    </div>
  );
};

export default ConfirmSummary;
