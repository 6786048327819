import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import './PaymentPage.css';

const PaymentPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();

  // Extract all parameters passed from previous pages
  const {
    budget, title, summary, description, ownerId, isCollaborating, collaboratingBusinessId, studentId, createdAt,
  } = location.state || {};

  const [clientSecret, setClientSecret] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const depositAmount = budget * 0.1; // 10% of the total value

  // Fetch clientSecret when the component mounts
  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch('/api/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ amount: budget }),
        });

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (err) {
        console.error('Error fetching payment intent:', err);
        setError('Failed to initiate payment.');
      }
    };

    createPaymentIntent();
  }, [budget]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!clientSecret) {
      setError('Payment processing error. Missing client secret.');
      setProcessing(false);
      return;
    }

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (error) {
        setError(`Payment failed: ${error.message}`);
        setProcessing(false);
        return;
      }

      if (paymentIntent.status === 'succeeded') {
        setSuccess(true);
        setError(null);
        setProcessing(false);

        // Add the project to Firestore with all the required parameters
        try {
          await addDoc(collection(db, 'projects'), {
            title,
            description,
            budget,
            summary,
            ownerId,
            isCollaborating: isCollaborating || false, // Default to false
            collaboratingBusinessId: collaboratingBusinessId || null, // Default to null
            studentId: studentId || ownerId, // Fallback to ownerId
            createdAt: createdAt || new Date(), // Fallback to current date
          });

          // Redirect to success page
          navigate('/success', {
            state: {
              title,
              budget,
              summary,
            },
          });
        } catch (uploadError) {
          console.error('Error uploading project:', uploadError);
          alert('Failed to upload project after payment.');
        }
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setError('Payment processing error.');
      setProcessing(false);
    }
  };

  return (
    <div className="payment-container">
      <form onSubmit={handleSubmit}>
        <h3>Total: ${depositAmount.toFixed(2)}</h3>
        <CardElement />
        <button type="submit" disabled={processing || success}>
          {processing ? 'Processing...' : `Pay $${depositAmount.toFixed(2)}`}
        </button>
        {error && <div className="card-error" role="alert">{error}</div>}
      </form>
    </div>
  );
};

export default PaymentPage;
