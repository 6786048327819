import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Import Firebase Firestore and Auth
import { collection, query, where, getDocs } from 'firebase/firestore';
import './MyStudentProjects.css';

const MyStudentProjects = () => {
  const [projects, setProjects] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchProjects = async () => {
      const user = auth.currentUser;

      if (user) {
        const projectsRef = collection(db, 'projects');
        const q = query(
          projectsRef,
          where('studentId', '==', user.uid),
          where('isCollaborating', '==', true) // Ensuring we fetch only projects that have active collaboration
        );
        const querySnapshot = await getDocs(q);

        const fetchedProjects = [];
        querySnapshot.forEach((doc) => {
          fetchedProjects.push(doc.data());
        });

        setProjects(fetchedProjects);
      }
    };

    fetchProjects();
  }, []);

  const nextProject = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const currentProject = projects[currentIndex];

  return (
    <div className="project-container">
      {projects.length > 0 ? (
        <>
          <div className="project-header">
            <div className="project-indicators">
              {projects.map((project, index) => (
                <div
                  key={index}
                  className={`indicator ${index === currentIndex ? 'active' : ''}`}
                ></div>
              ))}
            </div>
            <div className="project-info">
              <h2>{currentProject.title}</h2>
              <p className="project-price">{currentProject.price}</p>
            </div>
            <div className="project-summary">
              <p>{currentProject.summary}</p>
            </div>
            <div className="project-collaborator">
              <span>x</span>
              <h3>{currentProject.businessName}</h3>
            </div>
            <div className="collaborate">
              <p>collaborate</p>
            </div>
          </div>
          <div className="arrow-container" onClick={nextProject}>
            <span className="arrow">↓</span>
          </div>
        </>
      ) : (
        <p className="no-projects">You have no active projects. Apply for a project!</p>
      )}
    </div>
  );
};

export default MyStudentProjects;
