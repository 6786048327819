// src/components/StudentDash.js
import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig'; // Import auth and db from your Firebase config
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './StudentDash.css';
import Footer from '../components/Footer';

const StudentDash = () => {
    const [loaded, setLoaded] = useState(false);
    const [showLinks, setShowLinks] = useState(false);
    const [firstName, setFirstName] = useState('');
    const navigate = useNavigate();
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setLoaded(true);
      }, 500);
  
      return () => clearTimeout(timer);
    }, []);
  
    useEffect(() => {
      // Fetch the user's first name from Firestore
      const fetchUserName = async () => {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setFirstName(userDoc.data().firstName); // Assuming 'firstName' is stored in Firestore
          }
        }
      };
  
      fetchUserName();
    }, []);
  
    const toggleLinks = () => {
      setShowLinks(!showLinks);
    };
  
  return (
    <div className={`dashboard-container11 ${loaded ? 'loaded' : ''}`}>
      <div className={`upper-section11 ${showLinks ? 'show-links' : ''}`}>
        <div className="dashboard-header11">
          <h1>Dashboard</h1>
          <h1 className="student11">Student</h1>
          <h1 className="home11" onClick={toggleLinks}>Home</h1>
          {showLinks && (
            <div className="additional-links11">
              <p onClick={() => navigate('/student-profile')}>account</p>
              <p>my projects</p>
              <p onClick={() => navigate('/project-board')}>project board</p>
              <p>collaborate</p>
              <p>support</p>
              <p onClick={() => auth.signOut()}>log out</p>
            </div>
          )}
        </div>
        <div className="welcome-message11">
          <h2>Welcome <span>{firstName}</span></h2>
        </div>
      </div>
      <div className="dashboard-links-container11">
        <div className="dashboard-links11">
          <span className="link-item11 left11">current projects <span className="arrow">→</span></span>
        <div className="center-links11">
          <span className="link-item11">applications <span className="arrow">→</span></span>
          <span className="link-item11">my bio <span className="arrow">→</span></span>
        </div>
          <span className="link-item11 right11">collaborate <span className="plus">+</span></span>
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};

export default StudentDash;
