import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig'; 
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './BusinessDash.css';
import Footer from '../components/Footer.js';
import rightarrow from '../images/right-arrow.png';

const BusinessDash = () => {
  const [loaded, setLoaded] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setFirstName(userDoc.data().firstName);
        }
      }
    };

    fetchUserName();
  }, []);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <div className={`dashboard-container1 ${loaded ? 'loaded' : ''}`}>
      <div className="upper-section1">
        <div className="dashboard-header1">
          <h1>Dashboard</h1>
          <h1 className="business1">Company</h1>
          <h1 className="home1" onClick={toggleLinks}>Home</h1>
          {showLinks && (
            <div className="additional-links1">
              <p onClick={() => navigate('/business-profile')}>account</p>
              <p>my projects</p>
              <p onClick={() => navigate('/applications')}>applications</p>
              <p onClick={() => navigate('/business-chats')}>collaborate</p>
              <p>support</p>
              <p onClick={() => auth.signOut()}>log out</p>
            </div>
          )}
        </div>
        <div className="welcome-message1">
          <h2>Welcome <span>{firstName}</span></h2>
        </div> 

      </div>
      <div className="dashboard-links-container1">
        <div className="dashboard-links1">
          <span className="link-item1 left1" onClick={() => navigate('/business-projects')}>current projects <span className="arrow123">
          <img src={rightarrow} alt="Right Arrow" className="arrow123" /></span></span>
        <div className="center-links1">
          <span className="link-item1" onClick={() => navigate('/applications')}>applications <span className="arrow123">
          <img src={rightarrow} alt="Right Arrow" className="arrow123" /></span></span>
          <span className="link-item1">my bio <span className="arrow123">
          <img src={rightarrow} alt="Right Arrow" className="arrow123" /></span></span>
        </div>
          <span className="link-item1 right1" onClick={() => navigate('/upload')}>upload a project<span className="plus">
          <img src={rightarrow} alt="Right Arrow" className="arrow123" />
          </span></span>
        </div>
      </div>
      <div><Footer/>
      </div>
      
    </div>
    
  );
};

export default BusinessDash;
