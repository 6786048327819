// src/components/ProjectDescription.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig'; // Ensure auth is imported
import './ProjectDescription.css'; // Importing the new CSS for styling

const ProjectDescription = () => {
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSummarize = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/summarize', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ description }),
      });
      const data = await response.json();
      const summary = data.summary || description;

      // Get additional required fields
      const ownerId = auth.currentUser?.uid || '';
      const isCollaborating = false;
      const collaboratingBusinessId = null;
      const studentId = ownerId;
      const createdAt = new Date();

      // Navigate to the confirmation summary page with all necessary fields
      navigate('/confirm-summary', {
        state: {
          summary,
          description,
          ownerId,
          isCollaborating,
          collaboratingBusinessId,
          studentId,
          createdAt,
        },
      });
    } catch (error) {
      console.error('Error summarizing project description:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="project-description-container">
      <h2 className="title">Upload Another Project</h2>
      <textarea
        className="description-box"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Type your project description here..."
      />
      <button onClick={handleSummarize} disabled={loading} className="submit-button">
        {loading ? 'Summarizing...' : 'submit'} <span className="arrow">→</span>
      </button>
    </div>
  );
};

export default ProjectDescription;
