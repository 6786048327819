import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { getIdTokenResult } from 'firebase/auth'; // Import this to get user role from the token
import './NavBar.css'; // Import the CSS file
import logo from '../images/logo.png';

function NavBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // New state for mobile menu
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoggedIn(true);
        try {
          const idTokenResult = await getIdTokenResult(user);
          setUserRole(idTokenResult.claims.role);
        } catch (error) {
          console.error('Error fetching user role:', error);
        }
      } else {
        setIsLoggedIn(false);
        setUserRole(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleDashboardClick = () => {
    if (userRole === 'business') {
      navigate('/business-dashboard');
    } else if (userRole === 'student') {
      navigate('/student-dashboard');
    } else {
      navigate('/');
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="bar">
      <nav className="navbar">
        <div className="container">
          <div className="logo" onClick={() => navigate('/')}>
            <img src={logo} alt="Moccet Logo" className="logo-image" />
          </div>

          {/* Hamburger Icon for Mobile */}
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            &#9776;
          </div>

          {/* Desktop and Mobile Links */}
          <div className={`links13 ${isMobileMenuOpen ? 'open' : ''}`}>
            <div className="linksystem13">
              <span onClick={() => navigate('/')}>support</span>
              {!isLoggedIn ? (
                <>
                  <span onClick={() => navigate('/signup')}>sign up</span>
                  <span onClick={() => navigate('/signin')}>login</span>
                </>
              ) : (
                <>
                  <span className="user" onClick={() => auth.signOut()} onClickCapture={() => navigate('/')}>logout</span>
                  <span className="user" onClick={handleDashboardClick}>dashboard</span>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
