import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SetBudget.css';

const SetBudget = () => {
  const [budget, setBudget] = useState(200);
  const location = useLocation();
  const {
    title, summary, description, ownerId, isCollaborating, collaboratingBusinessId, studentId, createdAt,
  } = location.state || {};
  const navigate = useNavigate();

  const increaseBudget = () => setBudget(budget + 100);
  const decreaseBudget = () => setBudget(Math.max(100, budget - 100));

  const handleNext = () => {
    // Navigate to the payment page with all parameters
    navigate('/payment', {
      state: {
        title,
        summary,
        description,
        budget,
        ownerId,
        isCollaborating,
        collaboratingBusinessId,
        studentId,
        createdAt,
      },
    });
  };

  return (
    <div className="set-budget-container">
      <div className="left-section">
        <p>✔️ your project delivered completed or money back guarantee</p>
        <p>✔️ fast service. projects get posted as soon as you upload</p>
        <p>✔️ collaborate at all points during your project to get exactly what you want done</p>
      </div>

      <div className="center-section">
        <span className="arrow-left" onClick={decreaseBudget}>&larr;</span>
        <h2>${budget}</h2>
        <span className="arrow-right" onClick={increaseBudget}>&rarr;</span>
      </div>

      <button className="upload-button" onClick={handleNext}>
        upload <span className="arrow">→</span>
      </button>
    </div>
  );
};

export default SetBudget;
