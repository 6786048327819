import React from 'react';
import AboutUs from './AboutUs1';
import HowItWorks from './HowItWorksTitle';
import './AboutPage.css';
import JoinClub from './JoinClub';

const AboutPage = () => {
  return (
    <div className="about-page">
      <AboutUs />
      <HowItWorks />
      <JoinClub/>
    </div>
  );
};

export default AboutPage;
