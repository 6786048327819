import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <div className='dashboard12'>
      <div className='header12'>
        <h1 className="logo12">moccet</h1>
        <h2 className="tagline12">where smart people connect</h2>
      </div>
      <div className="links12">
        <button className="link-button12" onClick={() => navigate('/aboutus')}>
          learn more
        </button>
        <button className="link-button12" onClick={() => navigate('/signup')}>
          sign up
        </button>
      </div>
    </div>
  );
}

export default Dashboard;
