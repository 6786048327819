import React, { useState } from 'react';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword, getIdTokenResult } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignIn.css'; // Import the CSS file

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async () => {
    setError(''); // Clear any previous errors

    try {
      await signInWithEmailAndPassword(auth, email, password);

      // Force refresh the token
      await auth.currentUser.getIdToken(true);
      const idTokenResult = await getIdTokenResult(auth.currentUser);

      if (idTokenResult.claims.role === 'student') {
        navigate('/student-dashboard');
      } else if (idTokenResult.claims.role === 'business') {
        navigate('/business-dashboard');
      } else {
        navigate('/');
      }

    } catch (error) {
      setError('Error signing in. Please check your credentials and try again.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSignIn();
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-content">
        <div className="login-left">
          <h2>log in</h2>
        </div>
        <div className="login-right">
          {error && <p className="error-text">{error}</p>}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
            onKeyPress={handleKeyPress}  // Add keypress handler here
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
            onKeyPress={handleKeyPress}  // Add keypress handler here
          />
        </div>
      </div>
      <div className="signin-button-container">
        <p onClick={handleSignIn}>Sign In</p>
      </div>
    </div>
  );
}

export default SignIn;
