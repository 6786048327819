import React, { useState, useEffect } from 'react';
import './WinWinModel.css';

const WinWinModel = () => {
  const [text, setText] = useState('');
  const fullText =
    'At Moccet, we empower the brightest minds from the world\'s top 25 universities to collaborate with forward-thinking companies, delivering groundbreaking projects that generate real, lasting value.';
  const typingSpeed = 50; // Adjust the typing speed here

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setText((prev) => prev + fullText[index]);
      index += 1;
      if (index === fullText.length) {
        clearInterval(intervalId);
      }
    }, typingSpeed);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  return (
    <div className="win-win-container">
      <h1 className="win-win-title">win-win model</h1>
      <p className="win-win-description">
        {text.split(' ').map((word, i) =>
          ['brightest minds', 'world\'s top 25 universities', 'forward-thinking companies', 'groundbreaking projects'].includes(word) ? (
            <span key={i} className="bold-text">{word}</span>
          ) : (
            word + ' '
          )
        )}
      </p>
    </div>
  );
};

export default WinWinModel;
