// src/components/Footer.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  
  return (
    <div className="footer">
      <div className="footer-bottom">
        <p>Copyright © 2024 Moccet. All rights reserved.</p>
        <div className="footer-links">
          <p onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
          <p onClick={() => navigate('/terms-of-use')}>Terms of Use</p>
          <p onClick={() => navigate('/legal')}>Legal</p>
          <p onClick={() => navigate('/site-map')}>Site Map</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
