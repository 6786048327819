import React, { useState, useEffect, useRef } from 'react'; // Add useRef
import { useParams } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { doc, getDoc, updateDoc, arrayUnion, setDoc, onSnapshot } from 'firebase/firestore';
import './ChatPage.css';

function ChatPage() {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [currentUserFirstName, setCurrentUserFirstName] = useState('');
  const [otherUserFirstName, setOtherUserFirstName] = useState('');
  const messagesEndRef = useRef(null); // Create a reference

  useEffect(() => {
    const chatRef = doc(db, 'chats', chatId);

    const initializeChat = async () => {
      try {
        const chatDoc = await getDoc(chatRef);

        if (chatDoc.exists()) {
          onSnapshot(chatRef, (doc) => {
            const fetchedMessages = doc.data().messages || [];
            setMessages(fetchedMessages);
          });
        } else {
          await setDoc(chatRef, { messages: [] });
        }
      } catch (error) {
        console.error('Error initializing chat:', error);
      }
    };

    const fetchUserDetails = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setCurrentUserFirstName(userDoc.data().firstName);
        }
      }
    };

    const fetchOtherUserDetails = async () => {
      try {
        const chatDoc = await getDoc(chatRef);
        if (chatDoc.exists()) {
          const messages = chatDoc.data().messages || [];
          const otherUserId = messages.length > 0 && messages[0].senderId !== auth.currentUser.uid
            ? messages[0].senderId
            : null;

          if (otherUserId) {
            const otherUserDoc = await getDoc(doc(db, 'users', otherUserId));
            if (otherUserDoc.exists()) {
              setOtherUserFirstName(otherUserDoc.data().firstName);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching other user details:', error);
      }
    };

    initializeChat();
    fetchUserDetails();
    fetchOtherUserDetails();
  }, [chatId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const chatRef = doc(db, 'chats', chatId);
    const message = {
      senderId: auth.currentUser.uid,
      message: newMessage,
      timestamp: new Date(),
    };

    try {
      await updateDoc(chatRef, {
        messages: arrayUnion(message),
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom every time messages change
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header">project one x Moccet</div>
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.senderId === auth.currentUser.uid ? 'sender' : 'recipient'}`}>
            {msg.senderId !== auth.currentUser.uid && (
              <span className="avatar">{otherUserFirstName.charAt(0)}</span>
            )}
            <span className="message-content">{msg.message}</span>
            {msg.senderId === auth.currentUser.uid && (
              <span className="user-name">{currentUserFirstName}</span>
            )}
            {msg.senderId !== auth.currentUser.uid && (
              <span className="user-name">{otherUserFirstName}</span>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Ref to scroll to */}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="type here..."
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSendMessage(); // Send message on Enter
          }}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
}

export default ChatPage;
