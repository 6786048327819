import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './MyBusinessProjects.css'; // Import CSS
import rightarrow from '../../images/right-arrow.png';
import leftarrow from '../../images/left-arrow.png';

function MyBusinessProjects() {
  const [projects, setProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [collaboratingStudentName, setCollaboratingStudentName] = useState(''); // New state to store the student's name
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      const user = auth.currentUser;
      if (user) {
        // Fetch projects uploaded by the current business user
        const q = query(
          collection(db, 'projects'),
          where('ownerId', '==', user.uid)
        );
        const querySnapshot = await getDocs(q);
        const projectsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjects(projectsList);
      }
    };
    fetchProjects();
  }, []);

  // Fetch the student's name from the 'applications' collection
  useEffect(() => {
    const fetchCollaboratingStudent = async () => {
      if (projects.length > 0) {
        const projectId = projects[currentProjectIndex].id;
        // Query the 'applications' collection for the project
        const applicationsQuery = query(
          collection(db, 'applications'),
          where('projectId', '==', projectId),
          where('isCollaborating', '==', true)
        );
        const applicationsSnapshot = await getDocs(applicationsQuery);
        if (!applicationsSnapshot.empty) {
          const application = applicationsSnapshot.docs[0].data();
          setCollaboratingStudentName(application.studentName);
        } else {
          setCollaboratingStudentName(''); // No collaborating student found
        }
      }
    };

    fetchCollaboratingStudent();
  }, [currentProjectIndex, projects]);

  const handleNextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const handlePreviousProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  if (projects.length === 0) {
    return <p>No projects found.</p>;
  }

  const currentProject = projects[currentProjectIndex];

  return (
    <div className="business-project-container">
      <div className="project-details">
        <div className="arrow-left" onClick={handlePreviousProject} style={{ visibility: projects.length > 1 ? 'visible' : 'hidden' }}>
          <img src={leftarrow} alt="Left Arrow" className="arrow" />
        </div>
        <div className="project-info">
          <h1 className="project-title">{currentProject.title}</h1>
          <p className="project-budget">${currentProject.budget}</p>
        </div>
        <div className="project-summary">
          <p>{currentProject.summary}</p>
        </div>
        <div className="collaboration-info">
          {collaboratingStudentName ? (
            <p className="collaborator-name">x {collaboratingStudentName}</p>
          ) : (
            <p className="review-applications" onClick={() => navigate('/applications')}>review applications</p>
          )}
        </div>
        <div className="arrow-right" onClick={handleNextProject} style={{ visibility: projects.length > 1 ? 'visible' : 'hidden' }}>
          <img src={rightarrow} alt="Right Arrow" className="arrow" /> 
        </div>
      </div>
    </div>
  );
}

export default MyBusinessProjects;


