import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function StudentChats() {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          // Change the query to fetch from the "chatRooms" collection
          const chatsQuery = query(collection(db, 'chatRooms'), where('studentId', '==', user.uid));
          const chatSnapshot = await getDocs(chatsQuery);
          const chatsList = chatSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setChats(chatsList);
  
          console.log('Fetched chats:', chatsList);  // Debugging line
        }
      } catch (error) {
        console.error('Error fetching chats:', error);
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };
  
    fetchChats();
  }, []);
  

  if (loading) return <p>Loading your chats...</p>;

  return (
    <div className="chats-container">
      {chats.length > 0 ? (
        chats.map(chat => (
          <div key={chat.id} className="chat-item">
            <h3>{chat.projectName || "Chat"}</h3> {/* Added a fallback in case projectName is missing */}
            <button onClick={() => navigate(`/student-chat/${chat.id}`)}>Open Chat</button>
          </div>
        ))
      ) : (
        <p>No chats available.</p>
      )}
    </div>
  );
}

export default StudentChats;
