// src/components/ImageCarousel.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation'; // Import Swiper navigation styles
import { Navigation } from 'swiper/modules'; // Import Navigation module from Swiper
import './ImageCarousel.css'; // Custom styles
import business1 from '../../images/business1.png';
import business2 from '../../images/business2.png';
import business3 from '../../images/business3.png';
import business4 from '../../images/business4.png';
import business5 from '../../images/business5.png';
import business6 from '../../images/business6.png';

const images = [
  { id: 1, image: business1 },
  { id: 2, image: business2 },
  { id: 3, image: business3 },
  { id: 4, image: business4 },
  { id: 5, image: business5 },
  { id: 6, image: business6 },
];

const ImageCarousel = () => {
  return (
    <div className="image-carousel">
     <p>For Businesses</p>
      <Swiper
        spaceBetween={30}
        slidesPerView={4} // Adjust this based on how many images you want visible
        navigation={true} // Enables the default navigation arrows
        modules={[Navigation]} // Registers the Navigation module
      >
        {images.map((image) => (
          <SwiperSlide key={image.id}>
            <div className="image-card">
              <img src={image.image} alt={`Slide ${image.id}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ImageCarousel;
