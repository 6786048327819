// src/components/HowItWorksTitle.js
import React from 'react';
import './HowItWorksTitle.css';
import ImageCarousel from './ImageCarousel';
import ImageCarouselCompany from './ImageCarouselCompany';

const HowItWorksTitle = () => {
  return (
    <div>
      <div className="carousel2">
        <ImageCarouselCompany/>
      </div>
      <div className="carousel1">
        <ImageCarousel />
      </div>

      </div>
  );
};

export default HowItWorksTitle;
