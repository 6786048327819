import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation();
  const { title, budget, summary } = location.state || {};
  const navigate = useNavigate();

  return (
    <div className="success-page-container">
      <h1>congratulations!</h1>
      <p className="confirmation-text">
        your project has been uploaded. go to my projects to assess candidates and get started.
      </p>

      <div className="project-details">
        <div className="project-title-budget">
          <h2>{title}</h2>
          <p>${budget}</p>
        </div>
        <div className="project-summary">
          <p>{summary || "one line summary of their project comes up here. they can then swipe to other projects they've uploaded."}</p>
        </div>
      </div>

      <button className="my-projects-button" onClick={() => navigate('/my-projects')}>
        my projects <span className="arrow">→</span>
      </button>
    </div>
  );
};

export default SuccessPage;
