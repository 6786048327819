import React from 'react';
import './AboutUs1.css';

const AboutUs1 = () => {
  return (
    <div className="about-container">
      <div className="about-left">
        <p>
          At Moccet, we <span>connect top-tier university talent</span> with{' '}
          <span>companies seeking excellence</span> for short term projects.
        </p>
        <p>
          Our <span>advanced AI matching</span>, secure transactions, and a{' '}
          <span>streamlined process</span>, Moccet creates a{' '}
          <span>win-win environment</span> where{' '}
          <span>smart people connect and thrive</span> on fascinating projects.
        </p>
      </div>
      <div className="about-right">
        <blockquote>
          “Researchers surveyed 60,000 Microsoft employees and found that{' '}
          <span>remote workers are more productive</span> than their in-office
          counterparts in the short-term.”
        </blockquote>
        <p>nature human behaviour</p>
      </div>
    </div>
  );
};

export default AboutUs1;

