import React, { useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import './MergedSignUp.css';

function SignUp() {
  const [isStudent, setIsStudent] = useState(true); // Toggle between Student and Business
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [university, setUniversity] = useState(''); // For students
  const [degree, setDegree] = useState(''); // For students
  const [year, setYear] = useState(''); // For students
  const [businessName, setBusinessName] = useState(''); // For businesses
  const [intendedProjects, setIntendedProjects] = useState(''); // For businesses

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userInfo = {
        role: isStudent ? 'student' : 'business',
        firstName,
        lastName,
        roleAssigned: false,
      };

      if (isStudent) {
        Object.assign(userInfo, { university, degree, year });
      } else {
        Object.assign(userInfo, { businessName, intendedProjects });
      }

      // Store user info in Firestore
      await setDoc(doc(db, 'users', user.uid), userInfo);

      // Send email verification
      await sendEmailVerification(user);
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      <div className="role-selection">
        <span
          className={isStudent ? 'active' : ''}
          onClick={() => setIsStudent(true)}
        >
          Student
        </span>
        <span
          className={!isStudent ? 'active' : ''}
          onClick={() => setIsStudent(false)}
        >
          Business
        </span>
      </div>
      <div className="form-container">
        <div className="form-labels">
          {isStudent ? (
            <>
              <p>first name</p>
              <p>last name</p>
              <p>university email</p>
              <p>university</p>
              <p>degree</p>
              <p>year</p>
              <p>password</p>
            </>
          ) : (
            <>
              <p>business name</p>
              <p>email address</p>
              <p>intended projects</p>
              <p>password</p>
            </>
          )}
        </div>
        <div className="form-inputs">
          {isStudent ? (
            <>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="jamie"
              />
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="safa"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="jamiesafa@hsph.harvard.edu"
              />
              <input
                type="text"
                value={university}
                onChange={(e) => setUniversity(e.target.value)}
                placeholder="harvard"
              />
              <input
                type="text"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                placeholder="cyber security"
              />
              <input
                type="text"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                placeholder="senior"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
            </>
          ) : (
            <>
              <input
                type="text"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                placeholder="moccet"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="support@moccet.com"
              />
              <input
                type="text"
                value={intendedProjects}
                onChange={(e) => setIntendedProjects(e.target.value)}
                placeholder="marketing; copywriting; coding"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
            </>
          )}
        </div>
      </div>
      <p className="send-verification" onClick={handleSignUp}>
        Send Verification
      </p>
      <p className="login-text">Already a member? <a href="/signin">Log In</a></p>
    </div>

  );
}

export default SignUp;
