import React from 'react';
import { useNavigate } from 'react-router-dom';
import './JoinClub.css';

function JoinClub() {

  const navigate = useNavigate();

  return (
    <div className="join-club-container">
      <h1 className="join-club-title">Join the club</h1>
      <p className="join-club-text">
        Brilliants Minds. Extraordinary Results.
      </p>
      <div className="join-club-signup">
        <button className="link-button" onClick={() => navigate('/signup')}>
          sign up
        </button>
      </div>
    </div>
  );
}

export default JoinClub;
