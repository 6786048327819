import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getIdTokenResult } from 'firebase/auth'; // Import this to get user role from the token
import './Profile.css';
import Footer from '../components/Footer';

const Profile = () => {
  const [loaded, setLoaded] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userRole, setUserRole] = useState(null); // State to store user role
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Fetch user role
          const idTokenResult = await getIdTokenResult(user);
          setUserRole(idTokenResult.claims.role);

          // Fetch user details from Firestore
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            setUserDetails(userDoc.data());
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUserRole(null);
        setUserDetails({});
      }
    });

    return () => unsubscribe();
  }, []);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  const handleDashboardClick = () => {
    if (userRole === 'business') {
      navigate('/business-dashboard');
    } else if (userRole === 'student') {
      navigate('/student-dashboard');
    } else {
      // If the role is not defined, fallback to a default page
      navigate('/');
    }
  };

  return (
    <div className={`dashboard-container ${loaded ? 'loaded' : ''}`}>
      <div className={`upper-section ${showLinks ? 'show-links' : ''}`}>
        <div className="dashboard-header">
          <h1>Dashboard</h1>
          <h1 className="student">Student</h1>
          <h1 className="home" onClick={toggleLinks}>account</h1>
          {showLinks && (
            <div className="additional-links">
              <p onClick={handleDashboardClick}>home</p>
              <p>my projects</p>
              <p onClick={() => navigate('/project-board')}>project board</p>
              <p>collaborate</p>
              <p>support</p>
              <p onClick={() => auth.signOut()}>log out</p>
            </div>
          )}
        </div>
        <div className="welcome-message">
          <div className="user-details">
            <div className="details-labels">
              <p>student name</p>
              <p>email address</p>
              <p>past projects</p>
              <p>bank details</p>
            </div>
            <div className="details-values">
              <p>{userDetails.firstName} {userDetails.lastName}</p>
              <p>{auth.currentUser?.email}</p>
              <p>{userDetails.pastProjects || 'N/A'}</p>
              <p>******</p>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-links-container2">
        <div className="dashboard-links2">
          <span onClick={handleDashboardClick}>home <span className="arrow">→</span></span>
        </div>
      </div>
      <div>
        <Footer/>
      </div>
    </div>
  );
};

export default Profile;
